import React, {useEffect} from 'react';
import {countdownContainer,countdownWidet, countdownText,countdownClock } from "./index.module.scss";
import moment from 'moment';


interface ClockProps {
    startTimeInHours: number
}


const Ticker = () => 
{

    var startdate  =  '04.03.2022 20:00:00';
    var exp = moment(startdate, 'DD.MM.YYYY HH:mm:ss').utc();
    const hourss = Math.abs( moment().diff(exp, 'hours'));
    const { hours = 0, minutes = 0, seconds = 60 } = {hours: hourss, minutes: 0, seconds: 0};
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);

    const reset = () => setTime([hours, minutes, seconds]);

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });


    const tick = () => {
        if (hrs === 0 && mins === 0 && secs === 0) 
            reset()
        else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };

    return (
        <div className={countdownContainer}>
            <div className={countdownWidet}>
                <h1 className={countdownText}>Project launch in...</h1>
                <p className={countdownClock}>{hrs.toString().padStart(2, '0')}:{mins
            .toString()
            .padStart(2, '0')}:{secs.toString().padStart(2, '0')}</p> 
            </div>
        </div>
    );
}



export default Ticker;